<template>
	<v-toolbar color="primary" dark>
		<v-toolbar-side-icon v-if="$vuetify.breakpoint.smAndDown || conditionalDisplay.isMobileApp" @click="showSideBar()" />
		<w-tooltip v-if="isPDFA" bottom>
			<template v-slot:activator="{ on }">
				<w-icon v-on="on">lock</w-icon>
			</template>
			<span v-text="$t('documents.preview.document_protected')" />
		</w-tooltip>
		<v-toolbar-title class="ml-0">
			<RenameButton v-if="!conditionalDisplay.isMobileApp" v-model="currentDocument" :items="items" single-tile-display></RenameButton>
		</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical />
		<DocumentActions v-if="$vuetify.breakpoint.mdAndUp || conditionalDisplay.isMobileApp" v-model="currentDocument" :has-multiple-pages="documentHasMultiplePages" :is-readonly="isReadonly" :is-editable="canEdit" :version="version" />
		<v-divider v-if="!conditionalDisplay.isMobileApp && $vuetify.breakpoint.lgAndUp" vertical />
		<DocumentPages
				v-if="!conditionalDisplay.isMobileApp && $vuetify.breakpoint.lgAndUp"
				v-model="currentDocument"
				:display-thumbnails="displayThumbnails"
				:is-readonly="isReadonly"
				:is-editable="canEdit"
				:page="page"
				:total-pages="totalPages"
				:version="version"
		>
		</DocumentPages>

		<v-toolbar-items v-if="totalDocuments > 1 && !singleFileMode">
			<v-divider  v-if="!conditionalDisplay.isMobileApp" vertical />
			<v-layout v-if="!conditionalDisplay.isMobileApp && $vuetify.breakpoint.mdAndUp" align-center>
				<v-flex v-t="'documents.preview.documents'" text-no-wrap ml-2 />
			</v-layout>
			<DocumentsNavigation v-model="index" :total="totalDocuments" />
		</v-toolbar-items>
		<v-divider v-if="canClose" vertical />
		<v-btn v-if="canClose" icon @click.stop="closePreview()">
			<v-icon>close</v-icon>
		</v-btn>
	</v-toolbar>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import PreviewAbstractBar from '@/components/Documents/Preview/PreviewAbstractBar.vue'
import { mimetypes } from '@/helpers/files'

export default {
	name: 'PreviewToolBar',
	components: {
		DocumentsNavigation: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentsNavigation')
		}),
		DocumentActions: () => ({
			component: import('@/components/Documents/Preview/PreviewToolBar/DocumentActions')
		}),
		RenameButton: () => ({
			component: import('@/components/Documents/Actions/Rename/RenameButton')
		})
	},
	extends: PreviewAbstractBar,
	mixins: [DocumentsManagerModuleGuard],
	props: {
		singleFileMode: {
			type: Boolean,
			required: false,
			default: false
		},
		closable: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		canClose: function () {
			return !this.singleFileMode && this.closable
		},
		isPDFA: function () {
			return this.currentDocument.type === mimetypes.pdf_a
		}
	},
	methods: {
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					actions: [
						{
							key: this.keyboard.escape,
							action: this.closeMe
						}
					]
				}
			]
		},
		closePreview: function () {
			if (this.singleFileMode) {
				this.closeTab()
			} else {
				this.closeMe()
			}
		},
		closeTab: function () {
			window.close()
		}
	}
}
</script>
